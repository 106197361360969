import React, { Component } from 'react'
import { trackPage } from '../../../utils/analytics'
import './styles.scss'
import LoginMagicLinkForm from '../login/login-magic-link'
import propTypes from 'prop-types'
import { toggleLoginSlide, setCoupon } from '../../../actions/checkout.actions'
import { updateAccountInfo } from '../../../actions/account.actions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { isAuthenticated } from 'utils/auth/auth'
import { campaigns } from '../../../constants/configs/campaigns'
import { ThemeProvider } from '@mui/material/styles'
import { createProvenTheme } from '../../../styles/theme-proven'
import { withUserTypeAccess } from '../../../hooks/useUserTypeAccess'
import { pathOr } from 'ramda'
import Spinner from '../../shared/spinner'
import withAuth from '../../../hoc/with-auth'
import { setIsWelcomeBackPage } from '../../../actions/app.actions'
import { withWidth } from '../../../hooks/useWidth'

const themeProven = createProvenTheme()

class WelcomeBackLogin extends Component {
  componentDidMount() {
    trackPage('Welcome Back page')

    this.props.setIsWelcomeBackPage(true)

    const now = new Date().getTime()
    const activeCampaign = campaigns.find(c => {
      return now > Date.parse(c.startDateTime) && now < Date.parse(c.endDateTime)
    })
    if (
      activeCampaign &&
      activeCampaign.autoApplyPromo &&
      activeCampaign.autoApplyPromo.length &&
      activeCampaign.autoApplyPromo.length > 0
    ) {
      this.props.setCoupon(activeCampaign.autoApplyPromo).then(() => {})
    }

    const { isAuthenticated, loading } = this.props
    // If user is authenticated, and it's not logging-in -> redirect to configured path
    if (isAuthenticated && !loading) {
      const search = (typeof window !== 'undefined' && window?.location?.search) || ''
      const pathToRedirect = this.props.getRedirectionPath(
        typeof window !== 'undefined' && window?.location?.pathname
      )
      // for the case where user just signed-in it is handled by onAfterAuthentication event.
      // We remove default direction from here since, there is a race condition until user has the data in redux
      //TODO handle redirections at the level of router/builderioIO page fetching
      if (pathToRedirect) {
        this.props.history.push(pathToRedirect + search)
      }
    }
  }

  componentWillUnmount() {
    this.props.setIsWelcomeBackPage(false)
    this.props.toggleLoginSlide(false)
  }

  onPasswordReset = e => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    this.props.toggleLoginSlide({
      view: 1,
      navBtnTxt: 'Close',
      closeFunc: this.closeLoginSlider,
      togglingEmailPassword: true
    })
  }

  onEmailPasswordRequest = (e, email) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    this.props.toggleLoginSlide(
      {
        view: 2,
        navBtnTxt: 'Close',
        closeFunc: this.closeLoginSlider,
        togglingEmailPassword: true
      },
      email
    )
  }

  closeLoginSlider = e => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    this.props.toggleLoginSlide(false)
    this.props.setIsWelcomeBackPage(true)
    this.props.updateAccountInfo({ isMagicLinkSent: false })
  }

  render() {
    const { width, title, loading, isAuthenticated, subTitle } = this.props
    const isMobile = width === 'xs' || width === 'sm'

    return (
      <ThemeProvider theme={themeProven}>
        {(loading || isAuthenticated) && <Spinner />}
        <LoginMagicLinkForm
          sx={{
            display: 'flex',
            flex: '1',
            width: '100%'
          }}
          title={title}
          subTitle={subTitle}
          isMobile={isMobile}
          onForgotPassword={this.onPasswordReset}
          onToggleEmailPassword={this.onEmailPasswordRequest}
        />
      </ThemeProvider>
    )
  }
}

WelcomeBackLogin.propTypes = {
  width: propTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  toggleLoginSlide: propTypes.func.isRequired,
  setCoupon: propTypes.func,
  isAuthenticated: propTypes.bool,
  subTitle: propTypes.string,
  title: propTypes.string
}

const mapStateToProps = state => {
  const loading = pathOr(false, ['auth', 'loading'], state)
  const error = pathOr(null, ['auth', 'error'], state)
  const errorUsername = pathOr(null, ['auth', 'errorUsername'], state)

  return {
    loading,
    error,
    errorUsername
  }
}

export default connect(mapStateToProps, {
  toggleLoginSlide,
  setCoupon,
  updateAccountInfo,
  setIsWelcomeBackPage
})(withUserTypeAccess(withWidth(withRouter(withAuth(WelcomeBackLogin)))))
